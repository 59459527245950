// Bootstrap JS
var bootstrap = require('bootstrap');
const simpleParallax = require('simple-parallax-js');
const {select} = require("nunjucks/src/filters");
const axios = require('axios').default;

const animateCSSInitialiseCallback = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            let element = entry.target;
            let animationName = element.dataset.animations;
            if (animationName.length > 0) {
                let updateInlineStyle = false;
                let newInlineStyle = "";
                if (element.hasAttribute('style')) {
                    newInlineStyle = element.getAttribute('style');
                }
                //Set the animation delay inline...
                let animationDelay = element.dataset.delay;
                if (animationDelay) {
                    newInlineStyle = newInlineStyle + ';animation-delay: ' + animationDelay;
                    updateInlineStyle = true;
                }

                let animationDuration = element.dataset.duration;
                if (animationDuration) {
                    newInlineStyle = newInlineStyle + ';animation-duration: ' + animationDuration;
                    updateInlineStyle = true;
                }

                if (updateInlineStyle) {
                    element.setAttribute('style', newInlineStyle);
                }

                let animationClasses = animationName.split(" ");
                element.classList.add("animate__animated");
                animationClasses.forEach(animationClass => {
                    element.classList.add("animate__" + animationClass);
                });
                element.classList.remove("animate");
            }

            // stop observing this element
            observer.unobserve(entry.target);
        }
    });
};

const options = {
    //root: document.getElementById('app' ),
    root: null,
    rootMargin: '-75px',
    threshold: 0
};
const animateCSSInitialiseObserver = new IntersectionObserver(animateCSSInitialiseCallback, options);

window.addEventListener("load", (event) => {
    //Setup the animated elements
    const animatedElements = document.querySelectorAll('.animate');
    animatedElements.forEach(animatedElement => {
        animateCSSInitialiseObserver.observe(animatedElement);
    });

    //let parallaxSettings= {};

    let images = document.querySelectorAll('img.parallax')

    images.forEach((parallaxImage) => {
        let parallaxSettings = {
            orientation: 'down',
            scale: 1.2,
            overflow: true,
            delay: 0.4,
            transition: 'cubic-bezier(0,0,0,1)',
            customContainer: "",
            customWrapper: "",
            maxTransition: 0
        };

        for (const property in parallaxSettings) {
            if (parallaxImage.hasAttribute('data-parallax-' + property)) {
                let propertyUpperCaseFirst = property.charAt(0).toUpperCase() + property.slice(1);
                let dataAttributeName = "parallax" + propertyUpperCaseFirst;
                let parallaxSettingValue = parallaxImage.dataset[dataAttributeName];
                parallaxSettings[property] = parallaxSettingValue;
            }
        }
        new simpleParallax(parallaxImage, parallaxSettings);
    });

    //Team Form Processing
    let teamEnquiryFormButton = document.getElementById('team-enquiry-button');
    let teamEnquiryForm = document.getElementById('team-enquiry');
    let teamEnquiryFormResponse = document.getElementById('team-enquiry-response');
    let teamEnquiryFormMessages = document.getElementById('team-enquiry-messages');
    teamEnquiryForm.addEventListener('submit', (event) => {
        event.preventDefault();
        let key = recaptcha_key;
        let action = 'team_enquiry';
        grecaptcha.ready(function () {
            grecaptcha.execute(key, {action: action}).then(function (token) {
                //Create the necessary hidden Recaptcha fields
                let tokenField = document.createElement("input");
                tokenField.setAttribute('type', 'hidden');
                tokenField.setAttribute('id', 'recaptcha-token');
                tokenField.setAttribute('name', 'token');
                tokenField.setAttribute('class', 'form-data');
                tokenField.setAttribute('value', token);

                let actionField = document.createElement("input");
                actionField.setAttribute('type', 'hidden');
                tokenField.setAttribute('id', 'recaptcha-action');
                actionField.setAttribute('name', 'action');
                actionField.setAttribute('class', 'form-data');
                actionField.setAttribute('value', action);

                //Attach the necessary hidden Recaptcha fields to the parent form
                teamEnquiryForm.appendChild(tokenField);
                teamEnquiryForm.appendChild(actionField);

                //Now submit the form... We'll want to do this with AJAX...
                let formData = new FormData();
                let formInputs = teamEnquiryForm.getElementsByClassName("form-data");
                for ( let i=0; i <formInputs.length; i++){
                    let formInput = formInputs[i];
                    formData.append(formInput.name,formInput.value );
                }

                axios.post( teamEnquiryForm.getAttribute('action'), formData )
                    .then(({data}) => {
                        teamEnquiryFormMessages.innerHTML = data.success;
                        teamEnquiryFormMessages.classList.remove('alert-error');
                        teamEnquiryFormMessages.classList.add('alert-success');
                    })
                    .catch( (error) => {
                        teamEnquiryFormMessages.innerHTML = error.response.data.error;
                        teamEnquiryFormMessages.classList.remove('alert-success')
                        teamEnquiryFormMessages.classList.add('alert-error');
                    })
                    .finally( () => {
                        teamEnquiryFormResponse.classList.remove('d-none');
                    });
            });
        });
    });
});


const navigationToggle = document.getElementById('main-navigation-open');
const navigationContainer = document.getElementById('navbar-container');
navigationToggle.addEventListener("click", (event) => {
    if (navigationToggle.classList.contains("open")) {
        navigationToggle.classList.remove("open");
        navigationContainer.classList.remove("open");
    } else {
        navigationToggle.classList.add("open");
        navigationContainer.classList.add('open');
    }

});
